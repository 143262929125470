import React, { useRef } from 'react';
import './../../output.css';
import Image from '../img/profil.jpg';
import './contact.css';
import emailjs from 'emailjs-com'; 

export default function Contact() {
  const form = useRef();

  const sendEmail = (e) => {
    e.preventDefault();

    emailjs
      .sendForm(
        'service_ot991qe',
        'template_89h9jgs',
        form.current,
        '93B52R8aiznjRmo-l' 
      )
      .then(
        () => {
          console.log('SUCCESS!');
        },
        (error) => {
          console.error('FAILED...', error);
        }
      );
  };

  return (
    <div className='contact Container unselect' id="contact_c">
      <div className="md:mt-[100px]">
        <header>
          <div className="text-2xl font-bold text-white mb-10 fadein-bot title-section flex items-center justify-center flex-col">
            <h4>ME CONTACTER</h4>
            <h4 className="text-base font-normal text-transparent bg-clip-text bg-gradient-to-r from-slate-100 to-pink-300">Si vous avez des questions, des propositions de collaboration ou simplement envie de discuter, n'hésitez pas à me contacter. Je serais ravi d'échanger avec vous !</h4>
          </div>
        </header>
        <div className="bg-[#1e1e1f] px-5 py-5 md:px-12 md:py-10 text-left border border-[#383838] rounded-3xl text-black-50 mx-3 mb-5">
          <article data-page="contact">
            <section className="text-sm md:text-lg text-justify flex flex-col gap-4 md:flex-row md:gap-8 md:justify-left md:items-center">
              <div className="flex justify-center">
                <img src={Image} alt='mon_image' width={400} className="rounded-full mb-3 fadein-up" />
              </div>
              <div className="md:w-7/12">
                <form ref={form} onSubmit={sendEmail}>
                  <div className="form-group">
                    <label htmlFor="name">Nom:</label>
                    <input type="text" id="name" name="name" required />
                  </div>
                  <div className="form-group">
                    <label htmlFor="email">Email:</label>
                    <input type="email" id="email" name="email" required />
                  </div>
                  <div className="form-group">
                    <label htmlFor="message">Message:</label>
                    <textarea id="message" name="message" rows="5" required></textarea>
                  </div>
                  <button type="submit" className='Envoie'>Envoyer</button>
                </form>
              </div>
            </section>
          </article>
        </div>
      </div>
    </div>
  );
}
