import React from 'react'
import { useTypewriter } from 'react-simple-typewriter'
import Image from '../img/profil.jpg';


export default function Accueil() {
    const [text] = useTypewriter({
        words: ['Développeur Web', 'Développeur Full Stack', 'Design Web ', 'Passionné du développement web'],
    })
    return (
    <div class="md:mt-[100px] Container" id="accueil_c">
        <main class='mt-10 md:mt-1 flex flex-col-reverse gap-8 items-center md:flex-row md:gap-16 md:justify-center min-h-[65vh] md:min-h-[80vh]'>
                    <img src={Image} alt='mon_image' width={400} class="profile rounded-full md:h-auto " />
                    <div class="space-y-2 text-center md:text-left px-10">
                        <p class="text-pink-500 text-2xl">BONJOUR TOUT LE MONDE JE SUIS </p>
                        <h1 class="text-4xl font-bold md:text-5xl text-white fadein-up">Syphax Haddou</h1>
                        <div className='App'>
                        <h1 class="typewrite text-xl font-semibold text-transparent bg-clip-text bg-gradient-to-r from-slate-100 to-pink-400 md:text-2xl fadein-up">{text}</h1>
                    </div>
                <p class="text-white">Bienvenue sur mon porto folio. <span class="wave">👋🏼</span></p>
            </div>
        </main>
    </div>
  )
}
