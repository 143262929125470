import React from 'react';
import './../../output.css';
import MAQUETTE from '../img/Maquette.png';
import PUISSANCE from '../img/PUISSANCE.SVG';
import SPOTIFY from '../img/Spotify.png';
import Twiter from '../img/Twiter.png';
import Github from '../img/github.png';


export default function Projetss() {
  return (
    <div className='Projetss Container unselect' id="Projetss_c">
      <div className="md:mt-[100px]">
        <div className="px-5 py-5 md:px-12 md:py-10 text-left text-amber-50 mx-3">
          <article data-page="projets">
            <header>
              <div className="text-2xl font-bold text-white mb-10 fadein-bot title-section flex items-center justify-center flex-col">
                <h4>Expérience de projet passée</h4>
                <h4 className="text-base font-normal text-transparent bg-clip-text bg-gradient-to-r from-slate-100 to-pink-300">Découvrez les projets sur lesquels j'ai travaillé jusqu'à présent</h4>
              </div>
            </header>
            <div>
              <div className='grid grid-cols-1 gap-4 pb-32 md:grid-cols-2 md:gap-2 xl:grid-cols-2 xl:gap-3 2xl:gap-5 fade-zoom-in'>
                <div className="item-card flex flex-col items-center gap-2 bg-[#1e1e1f] hover:bg-[#282828] border border-[#383838] rounded-xl text-amber-50 md:gap-3 px-5 py-5 lg:px-5">
                  <div className="flex items-center justify-center p-0 h-full w-full lg:p-0 zoom-in">
                    <img src={PUISSANCE} alt="mon_image" width={400} className="drop-shadow-xlrounded-xl" />
                  </div>
                  <div className='title-text font-medium text-secondary text-yellow-500'>PUISSANCE 4</div>
                  <div className='w-full flex flex-col gap-2 items-center text-sm md:text-base lg:text-lg'>
                    <div className='w-full text-left text-[10px] text-[#c1c1c1] md:text-xs lg:text-sm'>JS, HTML, CSS, ES6</div>
                    <div className='w-full mt-4 text-normal text-sm text-left text-pink-400'>Développe en 2 semaine / Projet individuel</div>
                    <div className='w-full flex justify-end'>
                    <a href="https://github.com/SyFouX-dev"><img src={Github} alt='icon_github' class="w-9 rounded-full" /></a>
                    </div>
                  </div>
                </div>
                <div className="item-card flex flex-col items-center gap-2 bg-[#1e1e1f] hover:bg-[#282828] border border-[#383838] rounded-xl text-amber-50 md:gap-3 px-5 py-5 lg:px-5">
                  <div className="flex items-center justify-center p-0 h-full w-full lg:p-0 zoom-in">
                    <img src={SPOTIFY} alt="mon_image" width={400} className="drop-shadow-xlrounded-xl" />
                  </div>
                  <div className='title-text font-medium text-secondary text-green-500'>SPOTIFY</div>
                  <div className='w-full flex flex-col gap-2 items-center text-sm md:text-base lg:text-lg'>
                    <div className='w-full text-left text-[10px] text-[#c1c1c1] md:text-xs lg:text-sm'>API, JSON, JAVASCRIPT, REACT</div>
                    <div className='w-full mt-4 text-normal text-sm text-left text-pink-400'>Développe en 2 jours / Projet groupe (4)</div>
                    <div className='w-full flex justify-end'>
                    <a href="https://github.com/SyFouX-dev"><img src={Github} alt='icon_github' class="w-9 rounded-full" /></a>
                    </div>
                  </div>
                </div>
                <div className="item-card flex flex-col items-center gap-2 bg-[#1e1e1f] hover:bg-[#282828] border border-[#383838] rounded-xl text-amber-50 md:gap-3 px-5 py-5 lg:px-5">
                  <div className="flex items-center justify-center p-0 h-full w-full lg:p-0 zoom-in">
                    <img src={Twiter} alt="mon_image" width={400} className="drop-shadow-xlrounded-xl" />
                  </div>
                  <div className='title-text font-medium text-secondary text-blue-500'>TWITTER</div>
                  <div className='w-full flex flex-col gap-2 items-center text-sm md:text-base lg:text-lg'>
                    <div className='w-full text-left text-[10px] text-[#c1c1c1] md:text-xs lg:text-sm'>PHP, HTML & CSS, JS, MYSQL</div>
                    <div className='w-full mt-4 text-normal text-sm text-left text-pink-400'>Développe en 1 mois / Projet groupe (4)</div>
                    <div className='w-full flex justify-end'>
                    <a href="https://github.com/SyFouX-dev"><img src={Github} alt='icon_github' class="w-9 rounded-full" /></a>
                    </div>
                  </div>
                </div>
                <div className="item-card flex flex-col items-center gap-2 bg-[#1e1e1f] hover:bg-[#282828] border border-[#383838] rounded-xl text-amber-50 md:gap-3 px-5 py-5 lg:px-5">
                  <div className="flex items-center justify-center p-0 h-full w-full lg:p-0 zoom-in">
                    <img src={MAQUETTE} alt="mon_image" width={400} className="drop-shadow-xlrounded-xl" />
                  </div>
                  <div className='title-text font-medium text-secondary text-orange-500'>MAQUETTE D’INTRO</div>
                  <div className='w-full flex flex-col gap-2 items-center text-sm md:text-base lg:text-lg'>
                    <div className='w-full text-left text-[10px] text-[#c1c1c1] md:text-xs lg:text-sm'>JS, HTML, CSS, ES6</div>
                    <div className='w-full mt-4 text-normal text-sm text-left text-pink-400'>Développe en 2 semaine / Projet individuel</div>
                    <div className='w-full flex justify-end'>
                    <a href="https://github.com/SyFouX-dev"><img src={Github} alt='icon_github' class="w-9 rounded-full" /></a>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </article>
        </div>
      </div>
    </div>
  );
}
