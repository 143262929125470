import React from 'react';
import './Home.css';
import './../../output.css';
import Github from '../img/github.png';
import Accueil from '../Accueil/Accueil';
import About from '../About/About';
import Projets from '../Projets/Projets';
import Contact from '../Contact/Contact';

export default function Home() {
    const displayBlock = (e) => {
        e.preventDefault();
        let elements = document.getElementsByClassName("button-nav");
        for (let i = 0; i < elements.length; i++) {
            elements[i].classList.add("unactive");
            elements[i].classList.remove("active");
        }
        e.target.classList.remove("unactive");
        e.target.classList.add("active");
        elements = document.getElementsByClassName("Container");
        for (let i = 0; i < elements.length; i++) {
            elements[i].classList.remove("unselect");
            elements[i].classList.add("unselect");
        }
        let element = document.getElementById(e.target.id + "_c");
        console.log(element)
        element.classList.remove("unselect");
    }

    return (
        <div className='Home max-w-7xl mx-auto flex flex-col relative'>
            <nav className="max-w-7xl px-5 md:fixed top-0 z-[98] w-screen backdrop-blur-md bg-[#1f1f1f] bg-opacity-80">
                <div className="container mx-auto flex flex-wrap items-center justify-between">
                    <button className="flex">
                        <span className="self-center text-lg text-[#c0268f] font-semibold whitespace-nowrap fadein-bot hover:text-pink-450">Syphax Haddou</span>
                    </button>
                    <ul className='hidden md:flex md:flex-row  md:space-x-8 mt-4 md:mt-0 md:text-sm md:font-medium'>
                        <li><a onClick={displayBlock} href="&" id="accueil" className="button-nav active">ACCUEIL</a></li>
                        <li><a onClick={displayBlock} href="&" id="about" className="button-nav unactive">ABOUT</a></li>
                        <li><a onClick={displayBlock} href="&" id="Projetss" className="button-nav unactive">PROJETS</a></li>
                        <li><a onClick={displayBlock} href="&" id="contact" className="button-nav unactive">CONTACT</a></li>
                    </ul>
                    <a href="https://github.com/SyFouX-dev"><img src={Github} alt='icon_github' className="w-9 rounded-full" /></a>
                </div>
            </nav>
            <Accueil></Accueil>
            <About></About>
            <Projets></Projets>
            <Contact></Contact>
            <footer className='block md:hidden fixed bottom-0 left-0 right-0 rounded-t-3xl border border-[#383838] bg-[#121212] bg-opacity-80 backdrop-blur-md backdrop-opacity-90'>
                <nav className="flex justify-around py-4 text-xs">
                    <ul className='router-link-active router-link-exact-active text-gray-300 hover:text-white'>
                        <li><a onClick={displayBlock} href="&" id="accueil" className="button-nav active">ACCUEIL</a></li>
                        <li><a onClick={displayBlock} href="&" id="about" className="button-nav unactive">ABOUT</a></li>
                        <li><a onClick={displayBlock} href="&" id="Projetss" className="button-nav unactive">PROJETS</a></li>
                        <li><a onClick={displayBlock} href="&" id="contact" className="button-nav unactive">CONTACT</a></li>
                    </ul>
                </nav>
            </footer>
        </div>
    );
}
