import React from 'react'
import Image from '../img/profil.jpg';
import Html from '../img/html.png';
import Css from '../img/css.png';
import php from '../img/php.png';
import JS from '../img/js.png';
import LARV from '../img/laravel.png';
import bts from '../img/bst.png';
import mysql from '../img/mysql.png';
import lua from '../img/lua.png';
import react from '../img/react.png';
import sy from '../img/sy.png';
import git from '../img/git.png';
import figma from '../img/Figma.png';
import './../../output.css';
import CV from '../img/Syphax Haddou CV.pdf';
import './about.css';





export default function About() {
  return (
    <div className='About Container unselect' id="about_c">
      <div class="md:mt-[100px]">
        <div class="bg-[#1e1e1f] px-5 py-5 md:px-12 md:py-10 text-left border border-[#383838] rounded-3xl text-amber-50 mx-3 mb-5">
          <article data-page="about">
            <header>
              <div class="text-2xl font-bold text-white mb-5 fadein-bot title-section flex items-center">
                About
                <div class="h-[1px] w-32 bg-pink-500 md:w-96 aos-init aos-animate"></div>
              </div>
            </header>
            <section class="text-sm md:text-lg text-justify flex flex-col gap-4 md:flex-row md:gap-8 md:justify-left md:items-center">
              <div class="flex justify-center">
                <img src={Image} alt='mon_image' width={900} class="rounded-full mb-3 fadein-up" />
              </div>
              <div class="text-lg">
                <p class="mb-3 md:mb-7 fadein-left fadeins-1">
                Actuellement en première année à la Web Académie Epitech, je suis passionné par le développement web et les technologies de l'information. Je suis à la recherche d'une alternance qui me permettra de mettre en pratique mes compétences en programmation et de développer de nouvelles compétences dans un environnement professionnel stimulant. Motivé et rigoureux, je suis prêt à relever de nouveaux défis et à contribuer activement aux projets de votre entreprise. N'hésitez pas à me contacter pour discuter de potentielles opportunités de collaboration !
                </p>
                {/* <p class="mb-3 fadein-left fadeins-2">
                  🌟 2024. Maintenant âgé de 18 ans, j'ai trouvé ma voie dans le développement Web. 🖥️ Pourquoi l'Web Dev ? Parce que c'est un domaine où je peux constamment apprendre et voir les résultats tangibles de mon travail sous forme de sites web et d'applications. Mais ce n'est pas tout, j'ai également intégré EPITECH, une école pas comme les autres. 🎓 Pas de cours magistraux, mais des projets à réaliser. Seul ou en groupe, j'apprends en pratiquant, en réalisant des projets concrets. 🛠️
                </p> */}
                <a href={CV} class="bg-pink-500 hover:bg-pink-600 text-white font-bold py-2 px-4 rounded" download>Télécharger mon CV</a>
              </div>
            </section>
          </article>

        </div>
        <div class="px-5 py-5 md:px-12 md:py-10 text-left text-pink-50 mx-3">
          <article data-page="about">
            <header>
              {/* <div class="text-2xl font-bold text-white mb-5 fadein-bot title-section flex items-center">
                <div class="h-[1px] bg-pink-500 md:w-20 ">
                  Langage
                </div>
              </div> */}
              <section>
                <div>
                  <ul class="flex flex-wrap text-sm font-medium text-center text-gray-500 dark:text-gray-400 mb-5">
                    <li class="mr-2"><button class="inline-block px-4 py-3 rounded-lg hover:text-white text-pink-500 bg-pink-500 bg-opacity-10">Compétences</button></li>
                  </ul>
                </div>
                <div>
                  <div class="grid grid-cols-2 gap-4 pb-32 md:grid-cols-3 md:gap-8 xl:grid-cols-4 xl:gap-10 2xl:gap-12">
                    <div class="item-tech flex cursor-pointer items-center gap-2 rounded border border-pink-500 px-2 py-2 hover:bg-pink-500 hover:bg-opacity-10 md:gap-3 lg:px-3">
                      <div class="flex h-12 w-12 items-center justify-center p-0 lg:h-16 lg:w-16 lg:p-2 zoom-in">
                        <img src={Html} alt='mon_image' width={400} class="img-tech drop-shadow-xl transition-all duration-300 h-[65%] w-[65%] lg:h-[85%] lg:w-[85%]" />
                      </div>
                      <div class="flex items-center text-sm md:text-base lg:text-lg">
                        <div class="tech font-medium text-secondary transition-all duration-300 translate-y-0">HTML</div>
                      </div>
                    </div>
                    {/* CSS */}
                    <div class="item-tech flex cursor-pointer items-center gap-2 rounded border border-pink-500 px-2 py-2 hover:bg-pink-500 hover:bg-opacity-10 md:gap-3 lg:px-3">
                      <div class="flex h-12 w-12 items-center justify-center p-0 lg:h-16 lg:w-16 lg:p-2 zoom-in">
                        <img src={Css} alt='mon_image' width={400} class="img-tech drop-shadow-xl transition-all duration-300 h-[65%] w-[65%] lg:h-[85%] lg:w-[85%]" />
                      </div>
                      <div class="flex items-center text-sm md:text-base lg:text-lg">
                        <div class="tech font-medium text-secondary transition-all duration-300 translate-y-0">CSS</div>
                      </div>
                    </div>
                    {/* PHP */}
                    <div class="item-tech flex cursor-pointer items-center gap-2 rounded border border-pink-500 px-2 py-2 hover:bg-pink-500 hover:bg-opacity-10 md:gap-3 lg:px-3">
                      <div class="flex h-12 w-12 items-center justify-center p-0 lg:h-16 lg:w-16 lg:p-2 zoom-in">
                        <img src={php} alt='mon_image' width={400} class="img-tech drop-shadow-xl transition-all duration-300 h-[65%] w-[65%] lg:h-[85%] lg:w-[85%]" />
                      </div>
                      <div class="flex items-center text-sm md:text-base lg:text-lg">
                        <div class="tech font-medium text-secondary transition-all duration-300 translate-y-0">PHP</div>
                      </div>
                    </div>
                    {/* JS */}
                    <div class="item-tech flex cursor-pointer items-center gap-2 rounded border border-pink-500 px-2 py-2 hover:bg-pink-500 hover:bg-opacity-10 md:gap-3 lg:px-3">
                      <div class="flex h-12 w-12 items-center justify-center p-0 lg:h-16 lg:w-16 lg:p-2 zoom-in">
                        <img src={JS} alt='mon_image' width={400} class="img-tech drop-shadow-xl transition-all duration-300 h-[65%] w-[65%] lg:h-[85%] lg:w-[85%]" />
                      </div>
                      <div class="flex items-center text-sm md:text-base lg:text-lg">
                        <div class="tech font-medium text-secondary transition-all duration-300 translate-y-0">JAVASCRIPT</div>
                      </div>
                      {/* BootStrap */}
                    </div>
                    <div class="item-tech flex cursor-pointer items-center gap-2 rounded border border-pink-500 px-2 py-2 hover:bg-pink-500 hover:bg-opacity-10 md:gap-3 lg:px-3">
                      <div class="flex h-12 w-12 items-center justify-center p-0 lg:h-16 lg:w-16 lg:p-2 zoom-in">
                        <img src={bts} alt='mon_image' width={400} class="img-tech drop-shadow-xl transition-all duration-300 h-[65%] w-[65%] lg:h-[85%] lg:w-[85%]" />
                      </div>
                      <div class="flex items-center text-sm md:text-base lg:text-lg">
                        <div class="tech font-medium text-secondary transition-all duration-300 translate-y-0">BOOSTRAP</div>
                      </div>
                    </div>
                    {/* BootStrap */}
                    <div class="item-tech flex cursor-pointer items-center gap-2 rounded border border-pink-500 px-2 py-2 hover:bg-pink-500 hover:bg-opacity-10 md:gap-3 lg:px-3">
                      <div class="flex h-12 w-12 items-center justify-center p-0 lg:h-16 lg:w-16 lg:p-2 zoom-in">
                        <img src={react} alt='mon_image' width={400} class="img-tech drop-shadow-xl transition-all duration-300 h-[65%] w-[65%] lg:h-[85%] lg:w-[85%]" />
                      </div>
                      <div class="flex items-center text-sm md:text-base lg:text-lg">
                        <div class="tech font-medium text-secondary transition-all duration-300 translate-y-0">REACT</div>
                      </div>
                    </div>
                    <div class="item-tech flex cursor-pointer items-center gap-2 rounded border border-pink-500 px-2 py-2 hover:bg-pink-500 hover:bg-opacity-10 md:gap-3 lg:px-3">
                      <div class="flex h-12 w-12 items-center justify-center p-0 lg:h-16 lg:w-16 lg:p-2 zoom-in">
                        <img src={mysql} alt='mon_image' width={400} class="img-tech drop-shadow-xl transition-all duration-300 h-[65%] w-[65%] lg:h-[85%] lg:w-[85%]" />
                      </div>
                      <div class="flex items-center text-sm md:text-base lg:text-lg">
                        <div class="tech font-medium text-secondary transition-all duration-300 translate-y-0">MYSQL</div>
                      </div>
                    </div>
                    <div class="item-tech flex cursor-pointer items-center gap-2 rounded border border-pink-500 px-2 py-2 hover:bg-pink-500 hover:bg-opacity-10 md:gap-3 lg:px-3">
                      <div class="flex h-12 w-12 items-center justify-center p-0 lg:h-16 lg:w-16 lg:p-2 zoom-in">
                        <img src={lua} alt='mon_image' width={400} class="img-tech drop-shadow-xl transition-all duration-300 h-[65%] w-[65%] lg:h-[85%] lg:w-[85%]" />
                      </div>
                      <div class="flex items-center text-sm md:text-base lg:text-lg">
                        <div class="tech font-medium text-secondary transition-all duration-300 translate-y-0">LUA</div>
                      </div>
                    </div>
                    {/* hello */}
                    <div class="item-tech flex cursor-pointer items-center gap-2 rounded border border-pink-500 px-2 py-2 hover:bg-pink-500 hover:bg-opacity-10 md:gap-3 lg:px-3">
                      <div class="flex h-12 w-12 items-center justify-center p-0 lg:h-16 lg:w-16 lg:p-2 zoom-in">
                        <img src={LARV} alt='mon_image' width={400} class="img-tech drop-shadow-xl transition-all duration-300 h-[65%] w-[65%] lg:h-[85%] lg:w-[85%]" />
                      </div>
                      <div class="flex items-center text-sm md:text-base lg:text-lg">
                        <div class="tech font-medium text-secondary transition-all duration-300 translate-y-0">LARAVEL</div>
                      </div>
                    </div>
                    {/* hello */}
                    <div class="item-tech flex cursor-pointer items-center gap-2 rounded border border-pink-500 px-2 py-2 hover:bg-pink-500 hover:bg-opacity-10 md:gap-3 lg:px-3">
                      <div class="flex h-12 w-12 items-center justify-center p-0 lg:h-16 lg:w-16 lg:p-2 zoom-in">
                        <img src={sy} alt='mon_image' width={400} class="img-tech drop-shadow-xl transition-all duration-300 h-[65%] w-[65%] lg:h-[85%] lg:w-[85%]" />
                      </div>
                      <div class="flex items-center text-sm md:text-base lg:text-lg">
                        <div class="tech font-medium text-secondary transition-all duration-300 translate-y-0">SYMFONY</div>
                      </div>
                    </div>
                    {/* hello */}
                    <div class="item-tech flex cursor-pointer items-center gap-2 rounded border border-pink-500 px-2 py-2 hover:bg-pink-500 hover:bg-opacity-10 md:gap-3 lg:px-3">
                      <div class="flex h-12 w-12 items-center justify-center p-0 lg:h-16 lg:w-16 lg:p-2 zoom-in">
                        <img src={git} alt='mon_image' width={400} class="img-tech drop-shadow-xl transition-all duration-300 h-[65%] w-[65%] lg:h-[85%] lg:w-[85%]" />
                      </div>
                      <div class="flex items-center text-sm md:text-base lg:text-lg">
                        <div class="tech font-medium text-secondary transition-all duration-300 translate-y-0">GIT</div>
                      </div>
                    </div>
                    {/* hello */}
                    <div class="item-tech flex cursor-pointer items-center gap-2 rounded border border-pink-500 px-2 py-2 hover:bg-pink-500 hover:bg-opacity-10 md:gap-3 lg:px-3">
                      <div class="flex h-12 w-12 items-center justify-center p-0 lg:h-16 lg:w-16 lg:p-2 zoom-in">
                        <img src={figma} alt='mon_image' width={400} class="img-tech drop-shadow-xl transition-all duration-300 h-[65%] w-[65%] lg:h-[85%] lg:w-[85%]" />
                      </div>
                      <div class="flex items-center text-sm md:text-base lg:text-lg">
                        <div class="tech font-medium text-secondary transition-all duration-300 translate-y-0">FIGMA</div>
                      </div>
                    </div>
                  </div>
                </div>
              </section>
            </header>
          </article>
        </div>
      </div>
    </div>
  )
}
